﻿/*
 * @class CanvasIcon
 * @aka L.CanvasIcon
 * @inherits Icon
 *
 * Represents a lightweight icon for markers that uses a simple `<canvas>`
 * element instead of an image. Inherits from `Icon` but ignores the `iconUrl` and shadow options.
 *
 * @example
 * ```js
 * var myIcon = L.canvasIcon();
 *
 * L.marker([50.505, 30.57], {icon: myIcon}).addTo(map);
 * ```
 *
 * By default, it is styled as a little white square with a shadow.
 */

L.CanvasIcon = L.Icon.extend({
    options: {
        // @section
        // @aka CanvasIcon options
        iconSize: [12, 12], // also can be set through CSS

        iconRender: function (canvas) {
            canvas.fillRect(0, 0, canvas.width, canvas.height);
        }
    },

    createIcon: function (oldIcon) {
        var canvas = (oldIcon && oldIcon.tagName === 'CANVAS') ? oldIcon : document.createElement('canvas'),
		    options = this.options;

        canvas.width = options.iconSize[0];
        canvas.height = options.iconSize[1];

        options.iconRender(canvas);

        this._setIconStyles(canvas, 'icon');

        return canvas;
    },

    createShadow: function () {
        return null;
    }
});

// @factory L.canvasIcon(options: CanvasIcon options)
// Creates a `CanvasIcon` instance with the given options.
L.canvasIcon = function (options) {
    return new L.CanvasIcon(options);
};