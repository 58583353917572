L.WindyLayer = L.Layer.extend({

  initialize: function (options) {
    this._map = null;
    this._frame = null;
    this._iframe = null;
    this._iframeDiv = null;
    this._class = options.className;
    this._name = options.name;
    this._src = options.src;

    L.setOptions(this, options);
  },

  addTo: function (map) {
    map.addLayer(this);
    return this;
  },  

  drawLayer: function () {
    try {
      var topLeft = this._map.containerPointToLayerPoint([0, 0]);
      L.DomUtil.setPosition(this._iframe, topLeft);
      /* if (this._iframe.contentWindow.setView != undefined)
        this._iframe.contentWindow.setView(this._map.getCenter(), this._map.getZoom()); */
      try {
        this._iframe.contentWindow.setView(this._map.getCenter(), this._map.getZoom());
        //this._iframe.contentWindow.postMessage({ center: this._map.getCenter(), zoom: this._map.getZoom() }, '*');
      }      
      /*else */ catch (err) {
        console.log(err);
        this._frame = null;
        this.needRedraw();
      }
    }
    finally {
      this._frame = null;
    }
  },

  needRedraw: function () {
    if (this._frame == null) {
      this._frame = L.Util.requestAnimFrame(this.drawLayer, this);
    }
    return this;
  },

  onAdd: function (map) {
    this._map = map;
    this._iframe = L.DomUtil.create('iframe', 'leaflet-layer');
    if (this._name != undefined)
      this._iframe.id = this._name;
    if (this._class != undefined)
      L.DomUtil.addClass(this._iframe, this._class);
    if (this._src != undefined)
      this._iframe.src = this._src;

    var _this = this;
    this._iframe.onload= function () {
      _this.needRedraw();
    };

    var size = this._map.getSize();
    this._iframe.width = size.x;
    this._iframe.height = size.y;    

    this._iframeDiv = L.DomUtil.create('div');
    this._iframeDiv.style['pointer-events'] = 'none';
    this._iframeDiv.appendChild(this._iframe);

    map._panes.overlayPane.appendChild(this._iframeDiv);

    map.on(this._getEvents(), this);

    var del = this._delegate || this;
    del.onLayerDidMount && del.onLayerDidMount(); // -- callback

    //this.needRedraw();
  },

  onRemove: function (map) {

    var del = this._delegate || this;

    del.onLayerWillUnmount && del.onLayerWillUnmount(); // -- callback

    map.getPanes().overlayPane.removeChild(this._iframeDiv);

    map.off(this._getEvents(), this);

    this._iframe = null;
  },

  //#region Private methods

  //-------------------------------------------------------------
  _getEvents: function () {

    var events = {
      movestart: this._onLayerWillMove,
      moveend: this._onLayerDidMove,
      resize: this._onLayerDidResize
    };

    if (this._map.options.zoomAnimation && L.Browser.any3d) {
      events.zoomanim = this._animateZoom;
    }

    return events;
  },

  //-------------------------------------------------------------
  _onLayerWillMove: function () {
    L.DomUtil.setOpacity(this._iframe, 0);    
  },


  //-------------------------------------------------------------
  _onLayerDidMove: function () {

      this.drawLayer();

      L.DomUtil.setOpacity(this._iframe, 1);
  },

  //-------------------------------------------------------------
  _onLayerDidResize: function (resizeEvent) {
    this._iframe.width = resizeEvent.newSize.x;
    this._iframe.height = resizeEvent.newSize.y;
  },

  //#endregion
});

L.windyLayer = function (options) {
  return new L.WindyLayer(options);
};
