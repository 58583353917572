﻿L.Map.include({
    // @method latLngToContainerPoint(latlng: LatLng): Point
    // Given a geographical coordinate, returns the corresponding pixel coordinate
    // relative to the map container.
    latLngToContainerPointFloat: function (latlng) {
        return this.layerPointToContainerPoint(
            this.latLngToLayerPointFloat(L.latLng(latlng)));
    },

    // @method latLngToLayerPoint(latlng: LatLng): Point
    // Given a geographical coordinate, returns the corresponding pixel coordinate
    // relative to the [origin pixel](#map-getpixelorigin).
    latLngToLayerPointFloat: function (latlng) {
        var projectedPoint = this.project(L.latLng(latlng));
        return projectedPoint._subtract(this.getPixelOrigin());
    },
});